.cancelLine {
  position: relative;
}

.cancelLine td {
  opacity: 0.5;
}

.cancelLine td::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid black;
}