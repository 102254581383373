html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  background-color: "ghostwhite";
}

@media print{
  html * {
      visibility: hidden;
    }
    #print-section,
    #print-section * {
      visibility: visible;
    }
    #print-section {
      position: absolute;
      left: 0;
      top: 0;
      width: 1440px;
    }
}

.e-grid .e-headercell{
  background-color: #F4F4F4;
}

.e-grid .e-headercelldiv{
  font-weight: bold;
  font-size: 0.875rem;
}

.swal2-container {
  z-index: 20000 !important;
}