.c-error-row {
  background-color: #f8d7da !important;
}
.c-error-row:hover {
  background-color: #d8b3b7 !important;
}

.e-upload::before {
  content: "\e06f";
}

[data-tooltip-text] {
  width: 100%;
  color: #86111c;
  font-weight: bold;
}

[data-tooltip-text]:hover {
  position: relative;
}

[data-tooltip-text]:hover:after {
  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
  content: attr(data-tooltip-text);

  margin-bottom: 10px;
  top: 0;
  right: 0;
  padding: 2px 12px;
  position: absolute;
  width: auto;
  min-width: 50px;
  max-width: 300px;
  word-wrap: break-word;

  z-index: 9999;
}
