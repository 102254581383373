.e-list-icon.user{
  height: 100%;
}

.e-list-icon.user::before{
  content: "\e7fd";
  font-family: 'Material Icons';
  vertical-align: middle;
}

.e-list-icon.department {
  height: 100%;
}
.e-list-icon.department::before{
  content: "\e7ee";
  font-family: 'Material Icons';
  vertical-align: middle;
}
*:disabled{
  background-color: #EDEDED;
}